export const capitalize = str => (
  str.charAt(0).toUpperCase() + str.slice(1)
)

export const escapeRegExp = s => (
  s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
)

export const containsWord = (text, word) => (
  new RegExp(`\\b${escapeRegExp(word.toLowerCase())}\\b`).test(text.toLowerCase())
)

export const wrapMatched = (text, className = '') => (
  text.replace(/<em>(.*?)<\/em>/g, (_, str) => (
    `<span class='${className}'>${str}</span>`
  ))
)

export const sanitizeID = str => String(str).replace(/[\W]/gm, '')

export const joinWithEndWord = (array, endWord = 'and') => {
  if (array.length > 1) {
    return array.slice(0, -1).join(', ') + `, ${endWord} ` + array[array.length - 1]
  }

  return array[0] || ''
}

export const formatPeriodNamesWithDuplicates = (periods) => {
  return periods.map((period) => {
    const isDuplicate = periods.filter(p => p.name === period.name).length > 1
    return isDuplicate ? `${period.name} ${period.year}` : period.name
  })
}
