/* eslint-disable react/no-unknown-property */

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { PropTypes as MobxPropTypes } from 'mobx-react'
import { toJS } from 'mobx'
import Form from 'react-formal'
import { observer } from 'mobx-react'
import { isPresent } from '../../lib/utils/collection'
import { noop } from '../../lib/utils/common'
import { getId } from '../../lib/utils/selectors'
import { createListingSchema } from '../../schemas/listings'
import Modal from '../../components/Shared/Modal'
import { Select } from '../../components/Shared/Form'
import { ErrorMessages } from '../Shared/Form'
import AddressAutoCompleter from './AddressAutoCompleter'
import LoadingOverlay from '../LoadingOverlay'

const SelectPropertyAddressModal = ({ propertyStore, modalStore }) => {
  const [isLoading, setLoading] = useState(false)

  const handleSubmit = useCallback(() => {
    setLoading(true)
    propertyStore.submit(modalStore.listingType)
  }, [propertyStore])

  return (
    <Modal
      isOpen={modalStore.isModalOpen}
      onRequestClose={modalStore.closeModal}
      ariaHideApp={false}
      portalClassName='ReactModalPortal'
    >
      <Form className='Form'
        onSubmit={handleSubmit}
        schema={createListingSchema}
        value={propertyStore.toForm}
        onChange={propertyStore.onFormChange}
        errors={propertyStore.formErrors}
        onInvalidSubmit={propertyStore.setErrors}
        onError={noop}
        context={{ hasSubareas: isPresent(propertyStore.subareaOptions) }}
      >
        <div className='Form-wrap Form-wrap--extraSpace Form-wrap--wide'>
          <div className='Form-row'>
            <div className='Text Text--modalTitle Text--alignCenter'>
              { modalStore.modalTitle }
            </div>
          </div>
  
          <ErrorMessages messages={propertyStore.formErrors.base} contentClassName='ErrorMessages-content--slim'/>
  
          <div className='Form-row'>
            <label className='Label' htmlFor='select-listing-address'>Address or Tax Map ID</label>
            <Form.Field
              id='select-listing-address'
              as={AddressAutoCompleter}
              name='address'
              errorClass='isInvalid'
              onChangeAvailableAddresses={propertyStore.getAvailableAddresses}
              availableAddresses={toJS(propertyStore.availableAddresses)}
              onChangeSelectedAddressItem={propertyStore.setSelectedAddressItem}
              getItemValue={propertyStore.displayAddress}
            />
            <Form.Message
              className='ErrorText'
              for='address'
            />
          </div>
          { isPresent(propertyStore.subareaOptions) &&
            <div className='Form-row'>
              <label className='Label' htmlFor='select-listing-subarea'>Subarea</label>
              <Form.Field
                id='select-listing-subarea'
                as={Select}
                name='subarea'
                errorClass='isInvalid'
                options={toJS(propertyStore.subareaOptions)}
                mapFromValue={getId}
              />
              <Form.Message
                className='ErrorText'
                for='subarea'
              />
            </div>
          }
  
          <div className='Form-row'>
            <Form.Submit
              type='submit'
              className='Button Button--primary Button--withoutBorder Button--size13'
            >
              { modalStore.submitText }
            </Form.Submit>
          </div>
          <div className='Text Text--alignCenter'>
            <button type='button' className='Button Button--link' onClick={modalStore.closeModal}>
              Cancel
            </button>
          </div>
          <LoadingOverlay isLoading={isLoading}/>
        </div>
      </Form>
    </Modal>
  )
}

SelectPropertyAddressModal.propTypes = {
  propertyStore: PropTypes.shape({
    setSelectedAddressItem: PropTypes.func.isRequired,
    getAvailableAddresses: PropTypes.func.isRequired,
    availableAddresses: MobxPropTypes.arrayOrObservableArray.isRequired,
    subareaOptions: MobxPropTypes.arrayOrObservableArray,
    submit: PropTypes.func.isRequired,
    setField: PropTypes.func.isRequired,
    setErrors: PropTypes.func.isRequired,
    onFormChange: PropTypes.func.isRequired,
    displayAddress: PropTypes.func.isRequired,
    toForm: MobxPropTypes.objectOrObservableObject.isRequired,
    formErrors: MobxPropTypes.objectOrObservableObject.isRequired,
    selectedAddressItem: PropTypes.object
  }),
  modalStore: PropTypes.shape({
    modalTitle: PropTypes.string.isRequired,
    isModalOpen: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    listingType: PropTypes.string,
    submitText: PropTypes.string,
    setListingType: PropTypes.func
  })
}

export default observer(SelectPropertyAddressModal)
