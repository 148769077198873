import { useState, useCallback, useEffect } from 'react'

import Breakpoints from '../../lib/breakpoints'

const windowExists = typeof window !== 'undefined' ? window : { innerWidth: 0 }

const debounce = (func, ms) => {
  let timeoutId = null
  return (...args) => {
    if (timeoutId !== null) {
      clearTimeout(timeoutId)
    }
    timeoutId = setTimeout(() => {
      func(...args)
      timeoutId = null
    }, ms)
  }
}

const useResponsive = () => {
  const [currentWidth, setCurrentWidth] = useState(windowExists.innerWidth || 0)
  const updateWidth = useCallback(debounce(() => {
    setCurrentWidth(windowExists.innerWidth || 0)
  }, 500), [])

  useEffect(() => {
    window.addEventListener('resize', updateWidth)

    return () => {
      window.removeEventListener('resize', updateWidth)
    }
  }, [updateWidth])

  const isMobile = currentWidth <= Breakpoints.mobile
  const isTablet = currentWidth > Breakpoints.mobile && currentWidth <= Breakpoints.tablet
  const isDesktop = currentWidth > Breakpoints.tablet

  return { currentWidth, isMobile, isTablet, isDesktop }
}

export default useResponsive
