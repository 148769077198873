import { useState, useLayoutEffect } from 'react'

const useInitialDimensions = elementRef => {
  const [dimensions, setDimensions] = useState(null)

  useLayoutEffect(() => {
    const newDimensions = elementRef.current?.getBoundingClientRect()
    setDimensions(newDimensions)
  }, [elementRef])

  return dimensions
}

export default useInitialDimensions
