import React from 'react'
import { hideIfNoData } from '../../components/Hocs'
import { SpinnerUrl } from '../Svg'

const enhance = hideIfNoData(({ isLoading }) => !isLoading)

const LoadingOverlay = () => (
  <div className='LoadingOverlay'>
    <div className='LoadingOverlay-image'>
      <img alt='' src={SpinnerUrl} />
    </div>
  </div>
)

export default enhance(LoadingOverlay)
