import * as yup from 'yup'
import * as R from 'ramda'
import moment from 'moment'
import './methods'
import { uniqBy, isPresent, isEmpty } from '../lib/utils/collection'
import { isNull } from '../lib/utils/common'
import { identity } from '../lib/utils/selectors'
import { currentYear } from '../lib/utils/date'
import Messages from './messages'

yup.addMethod(yup.mixed, 'timeGreaterThan', function (ref, hoursCount, message) {
  return this.test('timeLessThan', message, function (value) {
    const timeFormat = 'HH:mm'
    const other = this.resolve(ref)
    const startTime = moment(other, timeFormat)
    const endTime = moment(value, timeFormat)

    return endTime.diff(startTime, 'hours') >= hoursCount
  })
})

yup.addMethod(yup.mixed, 'dateGreaterThan', function (ref, message) {
  return this.test('dateGreaterThan', message, function (value) {
    const dateFormat = 'YYYY-MM-DD'
    const other = this.resolve(ref)
    const startDate = moment(other, dateFormat)
    const endDate = moment(value, dateFormat)

    return endDate.isAfter(startDate.add(-1, 'days'))
  })
})

yup.addMethod(yup.mixed, 'dateLessThanTenYears', function (ref, message) {
  return this.test('dateLessThanTenYears', message, function (value) {
    const dateFormat = 'YYYY-MM-DD'
    const other = this.resolve(ref)
    const startDate = moment(other, dateFormat)
    const endDate = moment(value, dateFormat)

    return endDate.isBefore(startDate.add(10, 'years').add(1, 'days'))
  })
})

yup.addMethod(yup.mixed, 'dateSameOrAfter', function (ref, message) {
  return this.test('dateLessThanTenYears', message, function (value) {
    const dateFormat = 'YYYY-MM-DD'
    const endDate = moment(value, dateFormat)
    const todayDate = moment().format(dateFormat)

    return endDate.isSameOrAfter(todayDate)
  })
})

yup.addMethod(yup.mixed, 'uniqOpenHouses', function (message) {
  const fields = ['byAppointmentOnly', 'visibility', 'date', 'startTime', 'endTime']
  const DATE_FORMAT = 'YYYY-MM-DD'

  return this.test('uniqOpenHouses', message, function (arr) {
    if (isNull(arr)) {
      return true
    }

    const openHousesToValidate = arr.filter(({ date }) => (
      moment(date, DATE_FORMAT).isSameOrAfter(moment(), 'day')
    ))

    return (
      uniqBy(openHousesToValidate, v => JSON.stringify(R.pickAll(fields, v))).length === openHousesToValidate.length
    )
  })
})

yup.addMethod(yup.array, 'isPresent', function (message) {
  return this.test('isPresent', message, function (arr) {
    return isPresent(arr)
  })
})

yup.addMethod(yup.mixed, 'addressIsSelected', function (ref, message) {
  return this.test('addressIsSelected', message, function (value) {
    const other = this.resolve(ref)

    return isEmpty(value) || isPresent(other)
  })
})

export const openHouseSchema = yup.object({
  id: yup.number(),
  date: yup.string().nullable().required(),
  startTime: yup.string().nullable().required(),
  endTime: yup.string().nullable().required()
    .timeGreaterThan(yup.ref('startTime'), 0, 'End Time should be after the Start Time'),
  byAppointmentOnly: yup.bool().nullable(),
  visibility: yup.string().nullable()
})

export const openHousesSchema = yup.array().of(openHouseSchema)
  .nullable()
  .uniqOpenHouses('Two or more Open Houses have same date, start time and end time.')

export const image = yup.object({
  id: yup.number(),
  preview: yup.object({
    webp: yup.string().nullable(),
    jpeg: yup.string().nullable()
  })
})

export const rentalPrice = (periodName, pricePeriodDict) => yup.number()
  .nullable()
  .min(1000, `${pricePeriodDict[periodName].title} Price must be greater than or equal to $1,000`)
  .max(100000000, `${pricePeriodDict[periodName].title} Price must be less than or equal to $100,000,000`)
  .test(
    'isAvailableAndPresent',
    `${pricePeriodDict[periodName].title} price is required`,
    function (value) {
      return !this.parent.availability || isPresent(value)
    }
  )

export const rentalPrices = pricePeriodDict => yup.array().of(
  yup.object({
    year: yup.number(),
    value: yup.mixed().when(['year', '$activePricePeriodYears'], (year, activePricePeriodYears) => {
      return yup.object(Object.keys(pricePeriodDict).reduce((acc, periodName) => ({
        ...acc, [periodName]: yup.object({
          value: activePricePeriodYears.includes(year)
            ? rentalPrice(periodName, pricePeriodDict)
            : yup.number().nullable(),
          availability: yup.boolean()
        })
      }), {}))
    })
  })
).test(
  'atLeastOnePeriodIsEnabled',
  'This property does not yet have any pricing information, please enter it now',
  function (prices) {
    const activePricePeriodYears = this.options.context.activePricePeriodYears
    const availablePrices = prices.filter(({ year }) => activePricePeriodYears.includes(year))

    const priceAvailability = R.pipe(
      R.pluck('value'),
      R.map(R.pluck('availability')),
      R.map(R.values),
      R.flatten
    )(availablePrices)

    return (
      ['inactive', 'rented'].includes(this.parent.status)
        || priceAvailability.some(identity)
    )
  }
)

const dateForExclusive = yup.string().nullable().when('listingType', (listingType, schema) => {
  return listingType !== 'open-listing' ? schema.required() : schema
})

const COMMISSION_REGEX = /^(?!(.|\n)*([\n\s|]bac[^a-zA-Z]|^bac$|^bac[^a-zA-Z]|[\n\s]bac$|[\n\s|]fee[^a-zA-Z]|^fee$|^fee[^a-zA-Z]|[\n\s]fee$|concession|commission|compensation|percentage|%|concesion|comission|commision)(.|\n)*)/i

export const titleAndDescriptionRules = {
  title: yup.string().nullable().required(Messages.titleizedField.required)
    .max(65, 'Listing title can’t exceed 65 characters')
    .unacceptableWordsCheck(yup.ref('$unacceptableWords'), Messages.field.unacceptableWords('Title')),
  description: yup.string().nullable().required(Messages.titleizedField.required)
    .max(10000, 'Description must be less than or equal to 10000')
    .unacceptableWordsCheck(yup.ref('$unacceptableWords'), Messages.field.unacceptableWords('Description'))
  }

const US_ZIP_REGEX = /(^\d{5}$)|(^\d{5}-\d{4}$)/

const ownerBaseChecks = yup.object({
  title: yup.string().nullable(),
  name: yup.string().nullable().max(50, 'Owner name must have less than 50 characters').required(),
  additionalName: yup.string().nullable().max(50, 'Additional owner name must have less than 50 characters'),
  typeOfContact: yup.string().nullable().max(50, 'Owner type must have less than 50 characters'),
  email: yup.string().email(Messages.email.valid).nullable().required(),
  additionalEmail: yup.string().email(Messages.email.valid).nullable(),
  homePhone: yup.string().nullable().max(50, 'Owner home phone must have less than 50 characters'),
  mobilePhone: yup.string().nullable().max(50, 'Owner mobile phone must have less than 50 characters').required(),
  fax: yup.string().nullable().max(50, 'Owner fax must have less than 50 characters'),
  address: yup.string().nullable().max(100, 'Owner address must have less than 100 characters'),
  city: yup.string().nullable().max(50, 'Owner city must have less than 50 characters'),
  state: yup.string().nullable().max(50, 'Owner state must have less than 50 characters'),
  zip: yup.string().nullable().matches(US_ZIP_REGEX, 'Owner zip should match US zip format')
})

const ownerNotesWithoutCommissionCheck = yup.object({
  notes: yup.string().nullable().max(1000, 'Contact Additional Notes cannot exceed 5000 characters. You must shorten in order to publish')
})

const ownerNotesWithCommissionCheck = yup.object({
  notes: yup.string().nullable().max(1000, 'Contact Additional Notes cannot exceed 5000 characters. You must shorten in order to publish')
    .matches(COMMISSION_REGEX, "Contact Additional Notes cannot contain compensation related information, including commissions.")
})

const ownerWithoutCommission = yup.object().shape({
  ...ownerBaseChecks.fields,
  ...ownerNotesWithoutCommissionCheck.fields
})

const ownerWithCommission = yup.object().shape({
  ...ownerBaseChecks.fields,
  ...ownerNotesWithCommissionCheck.fields
})

export const editSaleSchema = yup.object({
  ...titleAndDescriptionRules,
  title: yup.string().nullable()
    .matches(COMMISSION_REGEX, "Title cannot contain compensation related information, including commissions."),
  description: yup.string().nullable()
    .matches(COMMISSION_REGEX, "Description cannot contain compensation related information, including commissions."),
  status: yup.string(),
  listingType: yup.string(),
  townName: yup.string(),
  beds: yup.number().nullable().min(0, 'Bedrooms must be greater than or equal to 0')
    .max(50, 'Bedrooms must be less than or equal to 50').required(),
  baths: yup.number().nullable().min(1, 'Bathrooms must be greater than or equal to 1')
    .max(50, 'Bathrooms must be less than or equal to 50').required(),
  halfBaths: yup.number().nullable().min(0, 'Half bathrooms must be greater than or equal to 0')
    .max(50, 'Half bathrooms must be less than or equal to 50'),
  sqft: yup.number().nullable().min(0, 'Listing Size must be greater than or equal to 0')
    .max(1000000, 'Listing Size must be less than or equal to 1000000'),
  acreage: yup.number().nullable().min(0, 'Property Size must be greater than or equal to 0')
    .max(300, 'Property Size must be less than or equal to 300'),
  price: yup.number().nullable().required().min(30000, 'Price must be greater than or equal to $30,000')
    .max(10000000000, 'Price must be less than or equal to $10,000,000,000'),
  amenityNames: yup.array().of(yup.string()).nullable(),
  listorIds: yup.array().of(yup.number()).nullable().isPresent('Please select at least one agent to publish'),
  openHouses: openHousesSchema,
  images: yup.array().of(image).nullable(),
  floorplans: yup.array().of(image).nullable(),
  surveys: yup.array().of(image).nullable(),
  imagesAiSorting: yup.boolean().nullable(),
  state: yup.string(),
  videoTour: yup.string().nullable(),
  newConstruction: yup.boolean(),
  folio: yup.string().nullable().max(100, 'Folio must be less than or equal to 100 characters'),
  privateNotes: yup.string().nullable()
    .max(5000, 'Private Notes must be less than or equal to 5000 characters')
    .matches(COMMISSION_REGEX, "Private Notes cannot contain compensation related information, including commissions."),
  showingInstructions: yup.string().nullable()
    .max(5000, 'Showing Instructions must be less than or equal to 5000 characters')
    .matches(COMMISSION_REGEX, "Showing Instructions cannot contain compensation related information, including commissions."),
  cornerLot: yup.boolean(),
  culDeSacLocation: yup.boolean(),
  waterAccess: yup.boolean(),
  lotWithTrees: yup.boolean(),
  nearJitneySunriseBusLine: yup.boolean(),
  nearTrainStation: yup.boolean(),
  exclusiveStartDate: dateForExclusive,
  exclusiveEndDate: dateForExclusive.when(['listingType', 'status'], (listingType, status, schema) => {
    if (listingType === 'open-listing' || ['off-the-market', 'sold'].includes(status)) { return schema }

    return schema
      .dateGreaterThan(yup.ref('exclusiveStartDate'),
        'Exclusivity end dates must be after start dates')
      .dateLessThanTenYears(yup.ref('exclusiveStartDate'),
        'Exclusivity end date must be less than 10 years after exclusive start date')
      .dateSameOrAfter(yup.ref('exclusiveEndDate'),
        'Exclusivity end date must not be in the past')
  }),
  unitType: yup.string().nullable().required(),
  style: yup.string().nullable(),
  stories: yup.number().nullable().min(1, 'Stories must be greater than or equal to 0')
    .max(50, 'Stories must be less than or equal to 50'),
  yearBuilt: yup.number().nullable()
    .min(1600, 'Year Build must be greater than 1600')
    .max(currentYear(), `Year Build must be less than or equal to ${currentYear()}`),
  zoning: yup.string().nullable().max(100, 'Zoning must be less than or equal to 100'),
  waterSource: yup.string().nullable(),
  taxes: yup.number().nullable()
    .max(100000000, 'Taxes must be less than or equal to $100,000,000'),
  coOpMaintenance: yup.number().nullable()
    .max(1000000, 'Co-op Maintenance must be less than or equal to $1,000,000'),
  condoFee: yup.number().nullable()
    .max(1000000, 'Condo Fee must be less than or equal to $1,000,000'),
  signAllowed: yup.boolean(),
  signPosted: yup.boolean(),
  keyNumber: yup.string().nullable().max(100, 'Key Number must be less than or equal to 100 characters'),
  keyComments: yup.string().nullable().max(1000, 'Key Comments must be less than or equal to 1000 characters'),
  alarmNumber: yup.string().nullable().max(100, 'Alarm Number must be less than or equal to 100 characters'),
  alarmComments: yup.string().nullable().max(1000, 'Alarm Comments must be less than or equal to 1000 characters'),
  owners: yup.array().of(ownerWithCommission),
  displayAddress: yup.boolean().nullable(),
  poolLength: yup.number().nullable(),
  poolWidth: yup.number().nullable(),
  fireplaces: yup.number().nullable(),
  carSpaces: yup.number().nullable(),
  visibility: yup.string().nullable(),
  subarea: yup.string().nullable().requiredIf('$hasSubareas', Messages.field.required('Subarea')),
  soldPrice: yup.number().nullable().min(30000, 'Price must be greater than or equal to $30,000')
    .max(10000000000, 'Price must be less than or equal to $10,000,000,000').requiredIf('$isListingSold'),
  estimatedSoldDate: yup.string().nullable().requiredIf('$isListingSold'),
  villageTax: yup.number().nullable()
    .max(100000000, 'Taxes must be less than or equal to $100,000,000'),
  mediaState: yup.string().nullable(),
  zillow3dTour: yup.string().nullable()
})

export const soldValuesSchema = yup.object({
  soldPrice: yup.number().nullable().defined().min(30000, 'Price must be greater than or equal to $30,000')
    .max(10000000000, 'Price must be less than or equal to $10,000,000,000').required(),
  estimatedSoldDate: yup.string().nullable().defined().required()
})

export const editRentalSchema = ({ pricePeriodDict }) => yup.object({
  ...titleAndDescriptionRules,
  base: yup.object(),
  status: yup.string(),
  listingType: yup.string(),
  townName: yup.string(),
  beds: yup.number().nullable().min(0, 'Bedrooms must be greater than or equal to 0')
    .max(50, 'Bedrooms must be less than or equal to 50').required(),
  baths: yup.number().nullable().min(1, 'Bathrooms must be greater than or equal to 1')
    .max(50, 'Bathrooms must be less than or equal to 50').required(),
  halfBaths: yup.number().nullable().min(0, 'Half bathrooms must be greater than or equal to 0')
    .max(50, 'Half bathrooms must be less than or equal to 50'),
  sqft: yup.number().nullable().min(0, 'Listing Size must be greater than or equal to 0')
    .max(1000000, 'Listing Size must be less than or equal to 1000000'),
  acreage: yup.number().nullable().min(0, 'Property Size must be greater than or equal to 0')
    .max(300, 'Property Size must be less than or equal to 300'),
  advertisedMonthlyPrice: yup.number().nullable(),
  subTitle: yup.string(),
  amenityNames: yup.array().of(yup.string()).nullable(),
  ruleNames: yup.array().of(yup.string()).nullable(),
  listorIds: yup.array().of(yup.number()).nullable().isPresent('Please select at least one agent to publish'),
  openHouses: openHousesSchema,
  images: yup.array().of(image).nullable(),
  floorplans: yup.array().of(image).nullable(),
  surveys: yup.array().of(image).nullable(),
  imagesAiSorting: yup.boolean().nullable(),
  state: yup.string(),
  videoTour: yup.string().nullable(),
  newConstruction: yup.boolean(),
  folio: yup.string().nullable().max(100, 'Folio must be less than or equal to 100 characters'),
  privateNotes: yup.string().nullable()
    .max(5000, 'Private Notes must be less than or equal to 5000 characters'),
  showingInstructions: yup.string().nullable().max(5000,
    'Showing Instructions must be less than or equal to 5000 characters'),
  cornerLot: yup.boolean(),
  culDeSacLocation: yup.boolean(),
  waterAccess: yup.boolean(),
  lotWithTrees: yup.boolean(),
  nearJitneySunriseBusLine: yup.boolean(),
  nearTrainStation: yup.boolean(),
  prices: rentalPrices(pricePeriodDict),
  exclusiveStartDate: dateForExclusive,
  exclusiveEndDate: dateForExclusive.when(['listingType', 'status'], (listingType, status, schema) => {
    if (listingType === 'open-listing' || status === 'rented') { return schema }

    return schema
      .dateGreaterThan(yup.ref('exclusiveStartDate'),
        'Exclusivity end dates must be after start dates')
      .dateLessThanTenYears(yup.ref('exclusiveStartDate'),
        'Exclusivity end date must be less than 10 years after exclusive start date')
      .dateSameOrAfter(yup.ref('exclusiveEndDate'),
        'Exclusivity end date must not be in the past')
  }),
  unitType: yup.string().nullable().required(),
  style: yup.string().nullable(),
  stories: yup.number().nullable().min(1, 'Stories must be greater than or equal to 0')
    .max(50, 'Stories must be less than or equal to 50'),
  yearBuilt: yup.number().nullable()
    .min(1600, 'Year Build must be greater than 1600')
    .max(currentYear(), `Year Build must be less than or equal to ${currentYear()}`),
  zoning: yup.string().nullable().max(100, 'Zoning must be less than or equal to 100'),
  waterSource: yup.string().nullable(),
  signAllowed: yup.boolean(),
  signPosted: yup.boolean(),
  keyNumber: yup.string().nullable().max(100, 'Key Number must be less than or equal to 100 characters'),
  keyComments: yup.string().nullable().max(1000, 'Key Comments must be less than or equal to 1000 characters'),
  alarmNumber: yup.string().nullable().max(100, 'Alarm Number must be less than or equal to 100 characters'),
  alarmComments: yup.string().nullable().max(1000, 'Alarm Comments must be less than or equal to 1000 characters'),
  owners: yup.array().of(ownerWithoutCommission),
  displayAddress: yup.boolean().nullable(),
  visibility: yup.string().nullable(),
  registrationCode: yup.string().nullable()
    .max(20, 'Rental registration number must be less than or equal to 20 characters'),
  registrationExpiration: yup.string().nullable(),
  poolLength: yup.number().nullable(),
  poolWidth: yup.number().nullable(),
  fireplaces: yup.number().nullable(),
  carSpaces: yup.number().nullable(),
  subarea: yup.string().nullable().requiredIf('$hasSubareas', Messages.field.required('Subarea')),
  mediaState: yup.string().nullable(),
  zillow3dTour: yup.string().nullable()
})

export const rentalPriceSchema = ({ pricePeriodDict }) => yup.object({
  prices: rentalPrices(pricePeriodDict),
  advertisedMonthlyPrice: yup.number().nullable()
})

export const editLandSchema = yup.object({
  ...titleAndDescriptionRules,
  title: yup.string().nullable()
    .matches(COMMISSION_REGEX, "Title cannot contain compensation related information, including commissions."),
  description: yup.string().nullable()
    .matches(COMMISSION_REGEX, "Description cannot contain compensation related information, including commissions."),
  status: yup.string(),
  listingType: yup.string(),
  townName: yup.string(),
  sqft: yup.number().nullable().min(0, 'Listing Size must be greater than or equal to 0')
    .max(1000000, 'Listing Size must be less than or equal to 1000000'),
  acreage: yup.number().nullable().min(0, 'Property Size must be greater than or equal to 0')
    .max(300, 'Property Size must be less than or equal to 300'),
  price: yup.number().nullable().required().min(30000, 'Price must be greater than or equal to $30,000')
    .max(10000000000, 'Price must be less than or equal to $10,000,000,000'),
  amenityNames: yup.array().of(yup.string()).nullable(),
  listorIds: yup.array().of(yup.number()).nullable().isPresent('Please select at least one agent to publish'),
  openHouses: openHousesSchema,
  images: yup.array().of(image).nullable(),
  floorplans: yup.array().of(image).nullable(),
  surveys: yup.array().of(image).nullable(),
  imagesAiSorting: yup.boolean().nullable(),
  state: yup.string(),
  videoTour: yup.string().nullable(),
  newConstruction: yup.boolean(),
  folio: yup.string().nullable().max(100, 'Folio must be less than or equal to 100 characters'),
  privateNotes: yup.string().nullable()
    .max(5000, 'Private Notes must be less than or equal to 5000 characters')
    .matches(COMMISSION_REGEX, "Private Notes cannot contain compensation related information, including commissions."),
  showingInstructions: yup.string().nullable().max(5000,
    'Showing Instructions must be less than or equal to 5000 characters')
    .matches(COMMISSION_REGEX, "Showing Instructions cannot contain compensation related information, including commissions."),
  cornerLot: yup.boolean(),
  culDeSacLocation: yup.boolean(),
  waterAccess: yup.boolean(),
  lotWithTrees: yup.boolean(),
  nearJitneySunriseBusLine: yup.boolean(),
  nearTrainStation: yup.boolean(),
  exclusiveStartDate: dateForExclusive,
  exclusiveEndDate: dateForExclusive.when(['listingType', 'status'], (listingType, status, schema) => {
    if (listingType === 'open-listing' || ['off-the-market', 'sold'].includes(status)) { return schema }

    return schema
      .dateGreaterThan(yup.ref('exclusiveStartDate'),
        'Exclusivity end dates must be after start dates')
      .dateLessThanTenYears(yup.ref('exclusiveStartDate'),
        'Exclusivity end date must be less than 10 years after exclusive start date')
      .dateSameOrAfter(yup.ref('exclusiveEndDate'),
        'Exclusivity end date must not be in the past')
  }),
  unitType: yup.string().nullable().required(),
  style: yup.string().nullable(),
  stories: yup.number().nullable().min(1, 'Stories must be greater than or equal to 0')
    .max(50, 'Stories must be less than or equal to 50'),
  yearBuilt: yup.number().nullable()
    .min(1600, 'Year Build must be greater than 1600')
    .max(currentYear(), `Year Build must be less than or equal to ${currentYear()}`),
  zoning: yup.string().nullable().max(100, 'Zoning must be less than or equal to 100'),
  waterSource: yup.string().nullable(),
  taxes: yup.number().nullable()
    .max(100000000, 'Taxes must be less than or equal to $100,000,000'),
  coOpMaintenance: yup.number().nullable()
    .max(1000000, 'Co-op Maintenance must be less than or equal to $1,000,000'),
  condoFee: yup.number().nullable()
    .max(1000000, 'Condo Fee must be less than or equal to $1,000,000'),
  signAllowed: yup.boolean(),
  signPosted: yup.boolean(),
  keyNumber: yup.string().nullable().max(100, 'Key Number must be less than or equal to 100 characters'),
  keyComments: yup.string().nullable().max(1000, 'Key Comments must be less than or equal to 1000 characters'),
  alarmNumber: yup.string().nullable().max(100, 'Alarm Number must be less than or equal to 100 characters'),
  alarmComments: yup.string().nullable().max(1000, 'Alarm Comments must be less than or equal to 1000 characters'),
  owners: yup.array().of(ownerWithCommission),
  displayAddress: yup.boolean().nullable(),
  visibility: yup.string().nullable(),
  subarea: yup.string().nullable().requiredIf('$hasSubareas', Messages.field.required('Subarea')),
  soldPrice: yup.number().nullable().min(30000, 'Price must be greater than or equal to $30,000')
    .max(10000000000, 'Price must be less than or equal to $10,000,000,000').requiredIf('$isListingSold'),
  estimatedSoldDate: yup.string().nullable().requiredIf('$isListingSold'),
  villageTax: yup.number().nullable()
    .max(100000000, 'Taxes must be less than or equal to $100,000,000'),
  mediaState: yup.string().nullable(),
  zillow3dTour: yup.string().nullable()
})

export const createListingSchema = yup.object({
  address: yup.string().nullable().required().addressIsSelected(yup.ref('propertyId'), Messages.propertyId.required),
  subarea: yup.string().nullable().requiredIf('$hasSubareas', Messages.field.required('Subarea'))
})
