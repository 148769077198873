import * as R from 'ramda'
import moment from 'moment'
import pluralize from 'pluralize'
import { isDefined } from './common'

export const DATE_FORMAT = 'YYYY-MM-DD'
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss'
export const UI_DATE_FORMAT = 'MM/DD/YYYY'
export const UI_DATE_TIME_FORMAT = 'MM/DD/YYYY h:mmA'

export const dynamicDateOptions = [{
  name: '',
  value: {
    from: null,
    to: null
  }
}, {
  name: 'Today',
  value: {
    from: 'now',
    to: 'now'
  }
}, {
  name: 'Tomorrow',
  value: {
    from: 'now',
    to: 'now+1d'
  }
}, {
  name: 'In the next 2 days',
  value: {
    from: 'now',
    to: 'now+2d'
  }
}, {
  name: 'In the next 4 days',
  value: {
    from: 'now',
    to: 'now+4d'
  }
}, {
  name: 'In the next 7 days',
  value: {
    from: 'now',
    to: 'now+7d'
  }
}]

export const formatDateValue = value => value ? value.format(DATE_FORMAT) : null
export const formatDateTimeValue = value => value ? value.format(DATE_TIME_FORMAT) : null

export const formatUIDateValue = value => value ? value.format(UI_DATE_FORMAT) : null

export const diffBetwenDateFromNow = endDate => {
  if(R.isNil(endDate)) {
    return null
  }
  const diffDateFromNow = moment(endDate, UI_DATE_FORMAT).diff(moment(), 'days')

  return `ending in ${pluralize('day', diffDateFromNow, true)}`
}

export const changeFormat = (value, from, to) => (
  value
    ? moment(value, from).format(to)
    : value
)

export const currentYear = () => (new Date(Date.now())).getFullYear()

export const dynamicDateOption = (from, to) => {
  const dateOption = dynamicDateOptions.find(v => v.value.from === from && v.value.to === to)

  if (isDefined(dateOption)) {
    return dateOption
  }

  if (isDefined(from) && isDefined(to)) {
    const fromDate = moment(from, DATE_FORMAT)
    const toDate = moment(to, DATE_FORMAT)

    return {
      name: `${formatUIDateValue(fromDate)} - ${formatUIDateValue(toDate)}`,
      value: { from, to }
    }
  }

  return dynamicDateOptions[0]
}

export const isCustomDateValue = value => (
  moment(value.from, DATE_FORMAT, true).isValid() &&
    moment(value.to, DATE_FORMAT, true).isValid()
)

export const daysLeft = (date, { format = 'MM/DD/YYYY' } = {}) => (
  moment(date, format).diff(moment({ hours: 0 }), 'days')
)

export const lessThanDaysLeft = (days, date, { format = 'MM/DD/YYYY' } = {}) => (
  daysLeft(date, { format }) < days
)

export const dateFromStartingPoint = (
  createdAt,
  startingPoint = '2018-04-06',
  defaultString = 'before April 2018'
) => {
  const date = moment(createdAt)
  return date.isBefore(moment(startingPoint)) ? defaultString : formatUIDateValue(date)
}

export const convertLocalToUTCDate = input_date => {
  if (!input_date) {
    return input_date
  }
  let date = new Date(input_date)
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return date
}

export const formatDate = ( date, format = 'MMM D, YYYY' ) => (
  moment(date).format(format)
)
